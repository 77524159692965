import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'SWE DEFI',
  description:
    'The most popular AMM on BSC by user count! Earn SWE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by SWE DEFI), NFTs, and more, on a platform you can trust.',
  image: 'https://swedefi.finance/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('SWE DEFI')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('SWE DEFI')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('SWE DEFI')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('SWE DEFI')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('SWE DEFI')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('SWE DEFI')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('SWE DEFI')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('SWE DEFI')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('SWE DEFI')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('SWE DEFI')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('SWE DEFI')}`,
      }
    default:
      return null
  }
}
